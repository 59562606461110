import { ApplicationConfig, NgModule } from "@angular/core";
import { provideRouter } from "@angular/router";
import { routes } from "./app.routes";
import { provideHttpClient, HttpClient } from "@angular/common/http";
import { provideAnimations } from "@angular/platform-browser/animations";
import { MessageService } from "primeng/api";
import { HttpService } from "./services/http.service";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    provideAnimations(),
    MessageService,
    HttpService,
    HttpClient,
  ],
};
