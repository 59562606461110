<p-toast key="toast-totp" position="center" class="totp">
    <ng-template let-message pTemplate="message">
      <h1>TOTP Verification</h1>
      <p>Please  enter the OTP (One-Time-Password) sent to your registered email/phone number to complete your verification.</p>
      <form [formGroup]="pinForm" (ngSubmit)="onLogin()">
        <div class="totp-inputs">
          <input type="tel" formControlName="pin1" pInputText maxlength="1" required autofocus (input)="focusNext(0)"
            (keydown)="onBackspace($event, 0)">
          <input type="tel" formControlName="pin2" pInputText maxlength="1" required (input)="focusNext(1)"
            (keydown)="onBackspace($event, 1)">
          <input type="tel" formControlName="pin3" pInputText maxlength="1" required (input)="focusNext(2)"
            (keydown)="onBackspace($event, 2)">
          <input type="tel" formControlName="pin4" pInputText maxlength="1" required (input)="focusNext(3)"
            (keydown)="onBackspace($event, 3)">
          <input type="tel" formControlName="pin5" pInputText maxlength="1" required (input)="focusNext(4)"
            (keydown)="onBackspace($event, 4)">
          <input type="tel" formControlName="pin6" pInputText maxlength="1" required (input)="focusNext(5)"
            (keydown)="onBackspace($event, 5)">
        </div>
        <div class="totp-options flex flex-col items-center justify-center gap-[2rem] w-full">
          <div class="flex items-center justify-center">
            <img src="https://img.jlmconsulting.services/fit-in/42x42/sls_1710918878173qr-code.png" alt="">
            <a type="button" (click)="getQrcode()">Get QR Code</a>
          </div>
          <p>Use another method <a type="button" (click)="getPincode()">Send Pin</a></p>
        </div>
        <div class="totp-buttons">
          <button type="button" pButton label="Cancel" class="cancel" (click)="clear()"></button>
          <button pButton pRipple type="button" label="Verify" [disabled]="!pinForm.valid" (click)="onLogin()" class="verify"></button>
        </div>
      </form>
    </ng-template>
  </p-toast>