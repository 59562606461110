import { MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { CarouselModule } from "primeng/carousel";
import { CheckboxModule } from "primeng/checkbox";
import { DividerModule } from "primeng/divider";
import { InputTextModule } from "primeng/inputtext";
import { MessagesModule } from "primeng/messages";
import { PasswordModule } from "primeng/password";
import { RippleModule } from "primeng/ripple";
import { ToastModule } from "primeng/toast";

import { CommonModule } from "@angular/common";
import { OnInit, NgModule, Component } from "@angular/core";
import {
    Validators, ReactiveFormsModule, FormsModule, FormGroup, FormBuilder
} from "@angular/forms";

import { TotpComponent } from "./components/totp/totp.component";
import { MockItem, HttpService } from "./services/http.service";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    ButtonModule,
    CarouselModule,
    DividerModule,
    RippleModule,
    CheckboxModule,
    ReactiveFormsModule,
    MessagesModule,
    FormsModule,
    ToastModule,
    PasswordModule,
    TotpComponent
  ],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  loginForm: FormGroup;

  passEmail = "";
  passUsername = "";
  
  constructor(
    private fb: FormBuilder,
    private userService: HttpService,
    private messageService: MessageService,
  ) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  get Email() {
    return this.loginForm.controls["email"];
  }

  onSignin(): void {
    if (!this.loginForm.valid) {
        this.messageService.add({ key: "toast-signin", severity: "error", summary: "Error", detail: "Please fill in all fields." });
        return;
    }

    const enteredEmail = this.loginForm.get("email")?.value;
    const enteredPassword = this.loginForm.get("password")?.value;

    this.userService.getItemsWithCredentials(this.loginForm.getRawValue()).subscribe(
      response => {
          const userData = JSON.parse(response.body)
          const userEmail = userData.email;
          const userPass = userData.password;
          const userName = userData.firstName;
          console.log(userData.firstName)
          if (enteredEmail === userEmail && enteredPassword === userPass) {
              this.passEmail = userEmail;
              this.passUsername = userName;
              this.messageService.add({ key: "toast-totp", severity: "info", summary: "Info", detail: "Login successful.", sticky: true });
          } else {
              this.messageService.add({ key: "toast-signin", severity: "error", summary: "Error", detail: "Incorrect email or password. Please try again." });
          }
      },
      error => {
          this.messageService.add({ key: "toast-signin", severity: "error", summary: "Error", detail: "Error validating credentials. Please try again." });
      }
    );
  }
}
