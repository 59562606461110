import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import { HttpService, MockItem } from "../../services/http.service";
import { ToastModule } from "primeng/toast";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";

@Component({
  selector: "app-totp",
  standalone: true,
  imports: [FormsModule, ToastModule, ReactiveFormsModule, ButtonModule, InputTextModule],
  templateUrl: "./totp.component.html",
  styleUrl: "./totp.component.scss"
})
export class TotpComponent {
  @Input() email: string | any;
  @Input() username: string | any;
  pinForm: FormGroup;
  validOtp = "111111"
  

  constructor(
    private fb: FormBuilder,
    private userService: HttpService,
    private messageService: MessageService,
  ) {
    this.pinForm = this.fb.group({
      pin1: ["", Validators.required],
      pin2: ["", Validators.required],
      pin3: ["", Validators.required],
      pin4: ["", Validators.required],
      pin5: ["", Validators.required],
      pin6: ["", Validators.required]
    });
  }

  clear() {
    this.messageService.clear("toast-totp");
    this.pinForm.reset();
  }

  onLogin(): void {

    const enteredOtp =  this.pinForm.get("pin1")?.value + this.pinForm.get("pin2")?.value + this.pinForm.get("pin3")?.value + this.pinForm.get("pin4")?.value + this.pinForm.get("pin5")?.value + this.pinForm.get("pin6")?.value;
      //check if entered otp is same to the value
    if(this.validOtp === enteredOtp){
      this.messageService.add({key: "toast-signin", severity: "success", summary: "Success", detail: "Login Success.", contentStyleClass: "success" });
    }else{
      this.messageService.add({key: "toast-signin", severity: "error", summary: "Error", detail: "Wrong OTP! Please try again."});
    }


      /* this.userService.getPin(this.email, enteredOtp)//change this to verifytotp
       .subscribe(
         response => {
           this.messageService.add({key: 'toast-signin', severity: 'success', summary: 'Success', detail: 'Login Success.', contentStyleClass: 'success' });
           // Handle successful login
         },
         error => {
           this.messageService.add({key: 'toast-signin', severity: 'error', summary: 'Error', detail: error});
           // Handle login error
         }
       ); */
  }

  focusNext(currentIndex: number): void {
    let nextControl: HTMLInputElement | null = null;
    const currentControlName = `pin${currentIndex + 1}`;
    const currentInputValue = this.pinForm.get(currentControlName)?.value;
    // If the current digit is the last one, trigger the form submission
    if (currentIndex === 5 && currentInputValue.trim()) {
      this.onLogin();
    } else {
      // Otherwise, construct the next control's name and focus it
      const nextControlName = `pin${currentIndex + 2}`;
      nextControl = document.querySelector(`input[formControlName="${nextControlName}"]`) as HTMLInputElement;
    }
    
    if(!currentInputValue.trim()){
    }else{
      if(nextControl) {
        nextControl.focus();
      }
    }
  }

  onBackspace(event: KeyboardEvent, currentIndex: number): void {
    if (event.key === "Backspace") {
      const currentControlName = `pin${currentIndex + 1}`;
      const currentInputValue = this.pinForm.get(currentControlName)?.value;

      // Check if the current input is empty, if so, move focus to the previous input
      if (!currentInputValue) {
          event.preventDefault(); // Stop the default behavior of the backspace key
          let previousControl: HTMLInputElement | null = null;

          // Only move to the previous field if it is not the first input
          if (currentIndex > 0) {
              const previousControlName = `pin${currentIndex}`;
              previousControl = document.querySelector(`input[formControlName="${previousControlName}"]`) as HTMLInputElement;
          }

          if (previousControl) {
            previousControl.focus();
          }
      }
    }
  }
 
  getQrcode() {
    this.userService.sendTotp(this.email, this.username)
    .subscribe(
      response => {
       this.messageService.add({key: "toast-signin",severity: "success", summary: "Sent!", detail: "Kindly Check your Email", contentStyleClass: "totp-toast", icon: "pi-check-circle" });
        // Handle successful login
      },
      error => {
        this.messageService.add({key: "toast-signin", severity: "error", summary: "Error", detail: error});
        // Handle login error
      }
    );
    
  }
  getPincode() {
    console.log(this.email, this.username);
    this.userService.sendPin(this.email, this.username)
    
    .subscribe(
      response => {
       this.messageService.add({key: "toast-signin",severity: "success", summary: "Sent!", detail: "Kindly Check your Email", contentStyleClass: "totp-toast", icon: "pi-check-circle" });
        // Handle successful login
      },
      error => {
        this.messageService.add({key: "toast-signin", severity: "error", summary: "Error", detail: error});
        // Handle login error
      }
    );
  }
}
