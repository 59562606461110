<div class="card flex lg:items-center lg:justify-center">
  
  <div class="surface-card p-7 shadow-2 border-round w-full xl:w-9 relative">
    <img src="https://img.jlmconsulting.services/fit-in/404x432/sls_1710918701284png1.png" alt="" class="absolute left-0 top-0 rounded-tl-md">
    <div class="absolute bg-image hidden xl:block">
      <img src="https://img.jlmconsulting.services/534x608/sls_1710918936313png2.png" alt="" class="w-10">
      <img src="https://img.jlmconsulting.services/269x242/sls_1710918946819png-cart.png" alt="" class="cart absolute w-4">
    </div>
    <div class="text-center mb-5 relative">
      <div class="text-900 title text-5xl font-medium mb-3 flex flex-col lg:flex-row items-center justify-center gap-3">
        <h1>Welcome Back</h1> 
        <span class="hidden lg:block">to </span>
        <h1>Bid n Buys!</h1>
      </div>
      <span class="text-600 font-medium line-height-3"
        >Sign in to continue</span
      >
    </div>

    <form [formGroup]="loginForm" class="relative ">
      <div class="lg:w-5 mx-auto">
        <label name="email" for="email" class="block text-900 font-medium mb-2"
          >Email</label
        >
        <div class="mb-3 flex flex-column items-center" id="inputEmail">
          <input
            id="email"
            type="text"
            name="email"
            placeholder="Email address"
            pInputText
            class="w-full mb-2"
            formControlName="email"
            required=""
            autocomplete="email"
          />
          <!-- display small if input email is touched or modified -->
          <small *ngIf="Email.invalid && (Email.dirty || Email.touched)" id="username-help" class="p-error text-center">
            <!-- display message if email is invalid -->
            <div *ngIf="Email.errors?.['email']">Please input valid email account.</div>
          </small>
        </div>

        <label
          name="password"
          for="password"
          class="block text-900 font-medium mb-2"
          >Password</label
        >
        <div class="flex align-items-center gap-1 mb-3">
          <input
            id="password"
            type="password"
            name="password"
            placeholder="Password"
            pInputText
            class="w-full"
            placeholder="Password"
            formControlName="password"
          />
        </div>

        <div class="flex flex-column lg:flex-row align-items-center justify-content-between mb-6">
          <div class="flex align-items-center mb-2 lg:m-0">
            <p-checkbox
              name="rememberme"
              id="rememberme"
              [binary]="true"
              styleClass="mr-2"
            ></p-checkbox>
            <label name="rememberme" id="rememberme" >Remember me</label>
          </div>
          <a
            id="f-pass"
            class="font-medium no-underline ml-2 text-right cursor-pointer"
            >Forgot password?</a
          >
        </div>

        <div class="actions">
          <button
            (click)="onSignin()"
            pButton
            pRipple
            label="Sign In"
            class="w-full p-btn shadow-5"
          ></button>
          <p class="m-3">Don't have an account?</p>
          <a href="signup" class="signUp">Signup</a>
        </div>
        
        <p-toast key="toast-signin" position="top-center" class="otp-toast"></p-toast>
      </div>
    </form>
  </div>
  <app-totp [email]="passEmail" [username]="passUsername"></app-totp>
</div>
