import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import * as _ from "lodash";
import { environment } from "../../../environments/environment";

export interface MockItem {
  email: string;
  password: string;
  firstname: string;
}

@Injectable({
  providedIn: "root",
})
export class HttpService {
  /* private apiUrl = "https://ivt25q4za5.execute-api.ap-southeast-1.amazonaws.com/dev/profile"; */
  private apiUrl = environment.endpoint + "/profile";
  private apiPin = environment.endpoint + "/sendPin";
  private apiTotp = environment.endpoint + "/sendTotp";
  private apiWebHook = environment.endpoint + "/mailgunwebhook";
  private apiverifyPin = environment.endpoint + "/getPin";

  constructor(private http: HttpClient) {}

  getItemsWithCredentials(profile: any): Observable<any> {
    return this.http.get<any>(this.apiUrl + "?email=" + profile.email);
  }

  sendTotp(email: string, firstName: string): Observable<any>{
    const body = { email, firstName };
    return this.http.post<any>(this.apiTotp, body);
  }

  sendPin(email: string, firstName: string): Observable<any>{
    const body = { email, firstName };
    return this.http.post<any>(this.apiPin, body);
  }
}
